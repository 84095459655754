/* Buy */
.filter-group{
    padding: 3rem 0 3rem;
}

.filter,
.sort {
    display: flex;
    align-items: center;
}

.filter {
    width: 100%;
    padding: .5rem 0 .5rem;
}

.sort {
    margin: 1.5rem 0 5rem;
}

.filter__text,
.sort__text {
    font-size: 1.7rem;
    font-weight: 600;
    color: var(--primary-grey);
    min-width: 7rem;
    margin-right: 3rem;
}


.dropdown__wrapper {
    width: 100%;
    position: relative;
}

.dropdown__wrapper:not(:last-child) {
    margin-right: 3rem;
}

.dropdown__top {
    width: 100%;
    position: relative;
    padding: .8rem 1.5rem;
    text-align: left;
    border: 1px solid var(--primary-grey);
    border-radius: 20px;
    cursor: pointer;
}

.dropdown__bottom {
    width: 100%;
    text-align: left;
    border: 1px solid var(--primary-grey);
    border-radius: 10px;
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    z-index: 10;
    background-color: white;
}

.dropdown__list {
    list-style: none;
    padding: .8rem 1.5rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--primary-grey);
    cursor: pointer;
}

.dropdown__lists-text {
    border-radius: inherit;
}

.dropdown__list:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.dropdown__list:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.dropdown__list:hover {
    background-color: var(--bg-yellow-light);
}

.dropdown__lists-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding: 1rem 1.2rem;
}

.filter-grid, .sort-grid {
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); */
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
}

.filter-item-grid, .sort-item-grid {
    width: 100%;
    position: relative;
}

.nft__lists-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 2rem;
    /* padding: 1rem 1.2rem; */
}

.nft__list-grid-item {
    aspect-ratio: 1;
}

.dropdown__list-grid-item-wrapper {
    aspect-ratio: 1;
    border-radius: 3px;
    padding: .2rem;
}

.dropdown__list-grid-item--selected {
    border: 1px solid var(--primary-grey);
}

.dropdown__list-grid-item {
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.center-text {
    text-align: center;
}

.dropdown__text {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--primary-grey);
}

.dropdown-header {
    display: flex;
    align-items: center;
}

.dropdown__box {
    height: 2rem;
    width: 2rem;
    border-radius: .2rem;
    margin-right: 1rem;
}

.square__box {
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 2px;
}

.filter__btn,
.sort__btn-wrapper {
    width: 100%;
    padding: .8rem 1.5rem;
    border: 1px solid var(--primary-grey);
    border-radius: 20px;
    cursor: pointer;
    background-color: transparent;
}

.filter__btn,
.sort__btn-text {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--primary-grey);
}

.filter__btn--selected, .sort__btn--selected, .dropdown__bottom__selected {
    background-color: var(--primary-grey);
    color: white;
    border: none;
}

.filter__btn:not(:last-child) {
    margin-right: 3.5rem;
}

.sort__btn-wrapper {
    position: relative;
    margin-right: 3rem;
    text-align: left;
}

.pagination__wrapper {
    margin: 8rem auto 3rem;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-number {
    list-style: none;
    margin: 0 1rem;
    font-size: 1.8rem;
    font-weight: 400;
    color: var(--primary-grey);
    cursor: pointer;
}

.arrow-up {
    transform: translate(0, 0) rotate(180deg) !important;
}

.owner-btn__wrapper {
    position: relative;
}

.owner-input__wrapper {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    z-index: 10;
}

.owner-input {
    width: 30rem;
    padding: .8rem 2rem;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
}

@media (max-width : 760px) {
    .filter,
    .sort {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 2rem;
        margin-bottom: 2rem;
    }

    .filter-grid, .sort-grid {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 2rem;
    }

    .filter__text,
    .sort__text {
        font-size: 3rem;
    }

    .dropdown__top, .dropdown__bottom {
        border: 1px solid var(--primary-grey);
    }

    .dropdown__text {
        font-size: 2.3rem;
    }
    .dropdown__text, .dropdown__list {
        color: var(--primary-grey);
    }

    .dropdown__list {
        list-style: none;
        padding: .8rem 1.5rem;
        font-size: 2rem;
        font-weight: 400;
        color: var(--primary-grey);
        cursor: pointer;
    }

    .filter__btn,
    .sort__btn-wrapper {
        border: 1px solid var(--primary-grey);
    }

    .filter__btn,
    .sort__btn-text {
        font-size: 2.3rem;
        font-weight: 400;
        color: var(--primary-grey);
    }

    .filter__btn--selected, .sort__btn--selected, .dropdown__bottom__selected {
        background-color: var(--primary-grey);
        color: white;
        border: none;
    }
    
}